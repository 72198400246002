import React, { Component } from 'react';
import {connect} from 'react-redux';

//Actions
import {
  apiGetVehicle, apiPostFinancingSimulator, clearDataVehicle, clearFinancingSimulator, setVehiclesState,
} from '../../../../common/modules/vehicles/VehiclesActions';

//Components
import ModalReservation from './ModalReservation';
import RangeInput from '../../components/RangeInput';

// Resource
import {
  toggleModal,
  closeModalBackdrop,
  removeTooltips,
  startCarousel,
  startTooltips, startSelectPicker, getDimensionElement,
} from '../../resources/styles/js/custom';
import {getAsset} from '../../../../common/resources/assets/assets';
import {strings} from '../../../../common/resources/locales/i18n';
import VehiclesManager from '../../../../common/modules/vehicles/VehiclesManager';
import {AsyncImage} from '../../components/AsyncImage';
import {Loading} from '../../components';
import Routing from '../routing/Routing';
import {Link} from 'react-router-dom';
import {addComparator} from '../../../../common/modules/comparator/ComparatorActions';
import {Modal} from '../../components/Modal';
import { ImageSvg } from '../../components/ImageSvg';
import { legalScripts_Default } from '../../../../common/utils/Utils';


class VehiclesDetailsContainer extends Component {

  state = {
    stateZoom: false,
  }

  async componentDidMount() {
    const { metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId, financeAllowedMonths } = this.props;
    const splitFinanceMonths = financeAllowedMonths.split(',');

    if (legalScripts_Default) legalScripts_Default({
      metasHomeTitle, metasHomeDescription, 
      cookiesBannerScript, metricsGtmEnabled, metricsGtmId
    });

    this._clearDataVehicle();
    await this._apiGetVehicle();
    await this._clearFinancing();
    await this._handleValueChange(
      'postponement', parseFloat(splitFinanceMonths[splitFinanceMonths.length - 1])
    );
    await this._apiPostFinancingSimulator();
    startTooltips();
    startSelectPicker();
  }

  render() {
    const {
      dataVehicleDetail, isLoadingVehicle, financeExampleLanding,
      vehicleLandingBanner, initialEntry, postponement,
      vehicleLandingGalleryBottom, financeSimulationAmountStep,
      financeAllowedMonths, isLoadingFinancing, financeSimulationMinAmount,
    } = this.props;

    if (isLoadingVehicle){
      return (
        <main id="main" role="main">
          <section id="product-info" className="pt-5">
            <div className='w-100 my-5 py-5'>
              <Loading/>
            </div>
          </section>
        </main>
      )
    }

    if (dataVehicleDetail === null){
      return (
        <>
        <main id="main" role="main">
          <section id="product-info" className="pt-5">
            <div className='w-100 my-5 py-5'>
              <div className="text-center">
                {strings('vehicleDetails.emptyListFilter')}
              </div>
            </div>
          </section>
        </main>
        </>
      )
    }

    const name = VehiclesManager.getName(dataVehicleDetail);
    const priceFormat = VehiclesManager.getPriceFormat(dataVehicleDetail);
    const finalPriceFormat = VehiclesManager.getFinalPriceFormat(dataVehicleDetail);
    const cuotaPriceFormat = VehiclesManager.getCuotaPriceFormat(dataVehicleDetail);
    const gearboxDescription = VehiclesManager.getGearboxDescription(dataVehicleDetail);
    const images = VehiclesManager.getImagesList(dataVehicleDetail);
    const environIconUrl = VehiclesManager.getEnvironIconUrl(dataVehicleDetail);
    const tyresPdfUrl = VehiclesManager.getTyresPdfUrl(dataVehicleDetail);
    const co2 = VehiclesManager.getCo2(dataVehicleDetail);
    const consumptionFormatted = VehiclesManager.getConsumptionFormatted(dataVehicleDetail);
    const power = VehiclesManager.getPower(dataVehicleDetail);
    const modelGroupDescription = VehiclesManager.getModelGroupDescription(dataVehicleDetail);
    const fuelDescription = VehiclesManager.getFuelDescription(dataVehicleDetail);
    const modelYear = VehiclesManager.getModelYear(dataVehicleDetail);
    const colorDescription = VehiclesManager.getColorDescription(dataVehicleDetail);
    const modelUrlKey = VehiclesManager.getModelUrlKey(dataVehicleDetail);
    const featuredEquipmentList = VehiclesManager.getFeaturedEquipmentList(dataVehicleDetail);
    
    /* FINANCING */
    const arrayMonth = financeAllowedMonths.split(',');
    const minMonth = parseFloat(arrayMonth[0]);
    const maxMonth = parseFloat(arrayMonth[arrayMonth.length - 1]);
    const stepMonth = parseFloat(arrayMonth[1]) - parseFloat(arrayMonth[0]);
    const maxInitialEntry = VehiclesManager.getFinalPrice(dataVehicleDetail);

    /* OPTIONAL EQUIPMENT LIST */
    const optionalEquipmentList = VehiclesManager.getOptionalEquipmentList(dataVehicleDetail);
    
    return (
      <>
        <main id="main" role="main">
          <section id="product-info" className="pt-4">
            <div className="container">

            <p className="d-none d-lg-block mb-lg-4 h6 small-lg">
              <Link replace to={`${Routing.vehicle}${modelUrlKey}`} className="text-muted">
                <ion-icon name="arrow-back-outline"/> {strings('models.backToTheList') + ' '}
                <span>{modelGroupDescription}</span>
              </Link>
            </p>

            <div className="row d-flex align-items-center">
        <div className="col-lg-7">
          <div className="gallery-wrapper position-relative">
            <div className="product-actions d-none d-lg-flex align-items-center">
            <Link
              replace to={Routing.nowhere}
              onClick={() => this._addComparator(dataVehicleDetail)}
              className="mr-2 p-2"
              data-toggle="tooltip"
              data-placement="top"
              title={strings('tooltip.addComparer')}
            >
              <ion-icon name="duplicate-outline"/>
            </Link>
            </div>
            {/* <!-- Galería --> */}
            <div className="mb-5">
              <div id="product-info-slider" className="owl-carousel owl-theme">
              {this._renderImages(images, modelGroupDescription)}
              </div>
            </div>
          </div>
          <p className='text-muted small'>{vehicleLandingGalleryBottom}</p>
        </div>
        <div className="col-lg-4 offset-lg-1">
          <div className="product-info">
            {/* <!-- Info + Price + CTA --> */}
            <div className="card">
              <div className="card-body product-name">
                <h1 className="text-primary h3 mb-1">{name}</h1>
                <p className="text-dark mb-2">{modelGroupDescription}</p>
                <ul className="list-inline small-lg">
                  {modelYear ?
                    <li className="list-inline-item mr-md-3">
                      <ion-icon name="calendar-outline"/>{' ' + modelYear}
                    </li> :
                    <></>
                  }
                  {fuelDescription ?
                    <li className="list-inline-item mr-md-3">
                      <ion-icon name="flash-outline"/>{' ' + fuelDescription}
                    </li> :
                    <></>
                  }
                  {gearboxDescription ?
                    <li className="list-inline-item mr-md-3">
                      <ion-icon name="cog-outline"/> {' ' + gearboxDescription}
                    </li> :
                    <></>
                  }
                  {colorDescription ?
                    <li className="list-inline-item">
                      <ion-icon name="color-filter-outline"/>{' ' + colorDescription}
                    </li> :
                    <></>
                  }
                </ul>
              </div>
              <div className="card-footer product-price">
                <div className="h6 text-dark text-uppercase small-lg mt-2">{strings('models.monthlyFee')}</div>
                <div className="h4 text-secondary mb-0">
                  {cuotaPriceFormat} <small>{strings('models.from2')}</small>
                </div>
                <ul className="list-inline small-lg mb-2">
                  <li className="list-inline-item mr-md-3">{financeExampleLanding}</li>
                </ul>
                <a href="#calculadora" className="text-primary h6">
                  <ion-icon name="calculator-outline"/>{' ' + strings('models.customizeQuotas')}
                </a>
              </div>
              <div className="card-footer product-price">
                <div className="h6 text-dark text-uppercase small-lg mt-2">{strings('models.spotPrice')}</div>
                <div className="h4 text-dark mb-2">
                  {finalPriceFormat !== priceFormat ? <s className="small-md text-muted mr-1">{priceFormat}</s> : <></>}
                  {' ' +finalPriceFormat}
                  </div>
              </div>
            </div>
            <div className="col-buy mt-0">
              <a className="btn btn-lg btn-block btn-primary" href="#" data-toggle="modal" data-target="#modalReservation">
                {strings('button.imInterested')}
              </a>
              <div className="product-actions-bottom d-flex d-lg-none align-items-center justify-content-center">
              <Link
                    replace to={Routing.nowhere}
                    onClick={() => this._addComparator(dataVehicleDetail)}
                    className="mr-2 p-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={strings('tooltip.addComparer')}
                  >
                    <ion-icon name="duplicate-outline"/>
                  </Link>
    	        </div>
            </div>
          </div>
        </div>
      </div>

            </div>
          </section>

          {optionalEquipmentList && optionalEquipmentList.length > 0 ?
            <section className='pt-4' id="optional-equipment">
              <div className="container">
                <div className="heading-section">
                  <h3 className="mb-0">{strings('vehicleDetails.optionalEquipment')}</h3>
                </div>
                <div className="row">
                  {this.renderOptionalEquipments('left')}
                  {this.renderOptionalEquipments('right')}
                </div>
              </div>
            </section> : 
            <></>
          }

          <section id="product-equipment">
            <div className="container">

              <div className="row d-flex align-items-center">
                <div className="col-md-4 mb-4 mb-md-0">
                  <div className="heading-section text-left mt-5 mb-0">
                    <p className="h6 text-uppercase text-dark">{strings('vehicleDetails.equipment')}</p>
                    {
                      featuredEquipmentList && featuredEquipmentList.length > 0 ?
                      <h2>{strings('vehicleDetails.standardEquipment')}</h2> : <></>
                    }
                    <p className="lead mb-md-0">
                      {strings('vehicleDetails.wantToKnowMore')}
                    </p>
                  </div>
                </div>
                <div className="col-md-7 offset-md-1">
                  <div className="accordion" id="accordionEquipment">
                    {this.renderFeaturedEquipmentList()}
                    {tyresPdfUrl ?
                      <div className="accordion-item">
                        <div className="accordion-header" id="headingFive">
                          <div className="h5 mb-0">
                            <a className="collapsed" role="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="accordionEquipment">
                              {strings('vehicleDetails.tireLabels')}
                              <ion-icon name="chevron-down-outline"/>
                            </a>
                          </div>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingFive"
                             data-parent="#accordionEquipment">
                          <div className="accordion-body">
                            <a href={tyresPdfUrl} rel="noreferrer" target="_blank">{strings('vehicleDetails.tireLabels')}
                              <ion-icon name="open-outline"/>
                            </a>
                          </div>
                        </div>
                      </div>
                      :
                      <div/>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="calculadora">
    <div className="container">
      <div className="heading-section">
        <h2>{strings('models.customizeFinancing')}</h2>
      </div>

      <div className="row row-cols-1 row-cols-md-2">
        <div className="col mb-4">
          <div className="card h-100">
            <div className="card-header h5 p-4">
              {strings('models.choosePrefences')}
            </div>
            <div className="card-body p-4 d-flex align-items-center">
              <form className='w-100' onSubmit={(e) => this._submitFinancing(e)}>
                <div className="form-group mb-4">
                  <RangeInput
                    id={'initialEntry'}
                    inputClean={{
                      value: true, minValue: 0,
                      maxValue: maxInitialEntry - financeSimulationMinAmount, step: financeSimulationAmountStep,
                      classNameLabel: 'd-flex mb-0', classNameInput: 'custom-range',
                      containLabel: <>{strings('vehicleDetails.initialEntry')} (€) <span className="ml-auto text-muted">{(initialEntry || initialEntry === 0) && initialEntry.toString() ? initialEntry.toString() : ''}{' '}€</span></>
                    }}
                    onChange={(e) => this._handleValueChange(e.target.id, parseFloat(e.target.value))}
                    value={initialEntry}
                  />
                </div>
                <div className="form-group mb-3">
                  <RangeInput
                      id={'postponement'}
                      inputClean={{
                        value: true, minValue: minMonth,
                        maxValue: maxMonth, step: stepMonth,
                        classNameLabel: 'd-flex mb-0', classNameInput: 'custom-range',
                        containLabel: <>{strings('vehicleDetails.postponement', {maxValue: maxMonth})} <span className="ml-auto text-muted">{strings('vehicleDetails.xMonths', {months: postponement})}</span></>
                      }}
                      onChange={(e) => this._handleValueChange(e.target.id, parseFloat(e.target.value))}
                      value={postponement}
                    />
                </div>
                <div className="form-group mt-5">
                  <button
                    className='btn btn-lg btn-block btn-primary'
                    type='submit'
                  >
                    {strings('vehicleDetails.simulate')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card h-100">
            <div className="card-header h5 p-4">
              {strings('vehicleDetails.financingDetails')}
            </div>
            <div className="card-body p-4">
              <dl className="row mb-0">
                {isLoadingFinancing ?
                  <div
                    className='d-flex align-items-center justify-content-center w-100'
                    style={{minHeight: '264px'}}
                  >
                    <Loading />
                  </div>
                  : this.renderResultFinancing()
                }
              </dl>
            </div>
          </div>
        </div>
      </div>

    </div>
          </section>

          <section id="product-details">
            <div className="container">
              <div className="heading-section">
                <p className="h6 text-uppercase text-muted">{strings('vehicleDetails.characteristics')}</p>
                <h2>{strings('vehicleDetails.surpriseAllBenefits')}</h2>
              </div>
              <div className="row d-flex align-items-center">
                <div className="col-md-7 mb-4 mb-md-0">
                  <ImageSvg src={vehicleLandingBanner}/>
                </div>
                <div className="col-md-5 col-lg-4 offset-lg-1">
                  <dl className="m-0 text-dark">
                    {power ?
                      <>
                        <dt className="float-left">{strings('vehicleDetails.power')}</dt>
                        <dd className="text-right">{strings('vehicleDetails.powerValue', {value: power})}</dd>
                        <hr/>
                      </>
                      : <div/>
                    }

                    {consumptionFormatted ?
                      <>
                        <dt className="float-left">{strings('vehicleDetails.consumption')}</dt>
                        <dd className="text-right">{consumptionFormatted}</dd>
                        <hr/>
                      </>
                      : <div/>
                    }

                    {co2 ?
                      <>
                        <dt className="float-left">{strings('vehicleDetails.emissions')}</dt>
                        <dd className="text-right">{strings('vehicleDetails.co2', {value: co2})}</dd>
                        <hr/>
                      </>
                      : <div/>
                    }

                    {gearboxDescription ?
                      <>
                        <dt className="float-left">{strings('vehicleDetails.shift')}</dt>
                        <dd className="text-right">{gearboxDescription}</dd>
                        <hr/>
                      </>
                      : <div/>
                    }

                    {environIconUrl && co2 === '0' ?
                      <>
                        <dt className="float-left">{strings('vehicleDetails.environmentalBadge')}</dt>
                        <dd className="text-right"><img src={environIconUrl} className="img-fluid w-25"/></dd>

                      </>
                      : <div/>
                    }

                  </dl>
                </div>
              </div>
            </div>
          </section>

  <div className="container d-flex justify-content-center align-items-center" style={{paddingBottom: '5rem'}}>
    <a className="col-12 col-lg-4 btn btn-lg btn-block btn-primary" href="#" data-toggle="modal" data-target="#modalReservation">
      {strings('button.imInterested')}
    </a>
  </div>

        </main>
        <button className="d-none" data-toggle="modal" data-target="#modalAddComparator" id='idAddComparator'/>
        {this._renderVehicleModalComparator()}
        <ModalReservation vehicle={dataVehicleDetail} history={this.props.history} />
      </>
    );
  }

  renderFeaturedEquipmentList () {
    const {dataVehicleDetail} = this.props;
    const featuredEquipmentList = VehiclesManager.getFeaturedEquipmentList(dataVehicleDetail);

    if (featuredEquipmentList && featuredEquipmentList.length !== 0){

      return featuredEquipmentList.map((item, index) => {
        return(
          <div className="accordion-item" key={index}>
            <div className="accordion-header" id="headingTwo">
              <div className="h5 mb-0">
                <a className="collapsed" role="button" data-toggle="collapse" data-target={"#" + item.title.replace(/\s/g, "")} aria-expanded={index === 0} aria-controls="accordionEquipment">
                  {item.title}
                  <ion-icon name="chevron-down-outline"/>
                </a>
              </div>
            </div>
            <div id={item.title.replace(/\s/g, "")} className={index === 0 ? "collapse show" : "collapse"} aria-labelledby="headingTwo"
                 data-parent="#accordionEquipment">
              <div className="accordion-body px-0">
                {
                  item.equipment.map((string ,indexString) => {
                    return <p key={indexString}>{string}</p>
                  })
                }
              </div>
            </div>
          </div>
        )
      })
    }
  }

  renderResultFinancing() {
    const { resultFinancing } = this.props;
    if (resultFinancing && resultFinancing.length > 0 && Array.isArray(resultFinancing)) {
      return resultFinancing.map((item,index) => {
        return (
          <div key={index} className='col-12 row mx-0 px-0'>
            <dd className={`col-7 ${item.value_code === 'tae' || item.value_code === 'quota' ? 'font-weight-bold' : ''}`}>{item.value_id}</dd>
            <dt className="col-5 text-right">{item.value_label}</dt>
          </div>
        )
      })
    }
  }

  renderOptionalEquipments(column) {
    const { dataVehicleDetail } = this.props;
    const optionalEquipment = VehiclesManager.getOptionalEquipmentList(dataVehicleDetail);
    const copyOptionalEquipment = optionalEquipment.slice();

    let middleArray = copyOptionalEquipment.length / 2;
    if (middleArray.toString().includes('.5')) {
      middleArray += 1;
    }
    middleArray = parseInt(middleArray);
    
    let column1 = copyOptionalEquipment.splice(0, middleArray);
    let column2 = copyOptionalEquipment;

    if (column === 'left') {
      return (
        <div className="col-md-6">
          <ul className="list-unstyled">
            {column1.map((item, index) => {
              return (
                <li key={index} className="py-1">
                  <ion-icon name="checkmark-outline" class="text-secondary mr-1"/>
                  <span dangerouslySetInnerHTML={{__html: item}}></span>
                </li>
              )
            })}
          </ul>
      </div>
      )
    } else if (column === 'right') {
      return (
        <div className="col-md-6">
          <ul className="list-unstyled">
            {column2.map((item, index) => {
              return (
                <li key={index} className="py-1">
                  <ion-icon name="checkmark-outline" class="text-secondary mr-1"/>
                  <span dangerouslySetInnerHTML={{__html: item}}></span>
                </li>
              )
            })}
          </ul>
      </div>
      )
    }
    
  }

  /** PRIVATE METHOD **/
  async _addComparator(vehicle) {
    const { dataComparator, vehiclesCompareMax } = this.props;

    if (dataComparator && dataComparator.length === parseInt(vehiclesCompareMax)) {

      toggleModal('idAddComparator');
    } else {
      await this.props.addComparator(vehicle);
      removeTooltips();
    }
  }

  async _apiGetVehicle() {
    const { idParams } = this.props;
    await this.props.apiGetVehicle(idParams);
    startCarousel();
  }

  _apiPostFinancingSimulator() {
    const { dataVehicleDetail } = this.props;
    this.props.apiPostFinancingSimulator(VehiclesManager.getFinalPrice(dataVehicleDetail));
  }

  async _clearDataVehicle() {
    await this.props.clearDataVehicle();
  }

  _onClickAccept() {
    const { history } = this.props;
    history.replace(Routing.comparator);
  }

  _renderCampaigns = (campaigns) => {
    if (campaigns && campaigns.length !== 0){

      return campaigns.map((item, index) => {
        return (
          <div className='d-flex align-items-center' key={index}>
            <span className='w-100 justify-content-start'> {item.label}</span>
            <span className='w-25'/>
            <p className='w-50 text-right align-content-end'>- {item.amount_formatted}</p>
          </div>
        )
      })
    }
  }


  _renderImages = (images, modelGroupDescription) => {
    if (images && images.length !== 0){
      const { stateZoom } = this.state;
      let dimensionsImg = {};
      if (getDimensionElement) dimensionsImg = getDimensionElement('product-info-slider');
      const widthScreen = window.innerWidth;

      return images.map((item, index) => {
        return (
          <div key={index} className='item' onClick={()=> this._setValueState('stateZoom', !stateZoom)}>
            <AsyncImage
              source={item}
              style={`bd-placeholder-img card-img-top ${widthScreen > 991 ? 'cursor-zoom-in' : ''}`}
              srcPlaceholder={getAsset('icPlaceholderModel')}
              minHeightLoading='184px'
              zoom={stateZoom && widthScreen > 991}
              zoomWidth={dimensionsImg && dimensionsImg.x ? dimensionsImg.x : 653}
              zoomHeight={dimensionsImg && dimensionsImg.y ? dimensionsImg.y : 367}
              alt={modelGroupDescription}
            />
          </div>
        )
      })
    }
  }

  _renderVehicleModalComparator() {
    const {vehiclesCompareMax} = this.props;
    return (
      <Modal
        body={strings('modals.comparatorFull.body', {value: vehiclesCompareMax})}
        classBody=''
        classBtnAccept='btn btn-primary'
        classBtnCancel='btn btn-secondary'
        idModal='modalAddComparator'
        onClickAccept={() => {
          if(closeModalBackdrop) {
            closeModalBackdrop();
          }
          this._onClickAccept();
        }}
        showBtnCancel={false}
        txtAccept={strings('modals.comparatorFull.txtAccept')}
        txtCancel={strings('modals.comparatorFull.txtCancel')}
        title={strings('modals.comparatorFull.title')}
      />
    )
  }

  _handleValueChange(prop, value) {
    this.props.setVehiclesState({prop, value});
  }

  _submitFinancing(e) {
    e.preventDefault();
    this._apiPostFinancingSimulator();
  }

  _clearFinancing() {
    this.props.clearFinancingSimulator();
  }

  _setValueState(prop, value){
    this.setState({[prop]: value});
  }
}

const mapStateToProps = ({ AppReducer, ComparatorReducer, VehiclesReducer }) => {
  const {
    financeExampleLanding, vehiclesCompareMax, metasHomeTitle,
    vehicleLandingBanner, metasHomeDescription, cookiesBannerScript,
    vehicleLandingGalleryBottom, metricsGtmEnabled, metricsGtmId,
    financeSimulationAmountStep, financeAllowedMonths, financeSimulationMinAmount,
  } = AppReducer
  const { dataComparator } = ComparatorReducer
  const {
    dataVehicleDetail, isLoadingVehicle, initialEntry,
    postponement, takeOutInsurance, interestingOption,
    resultFinancing, isLoadingFinancing,
  } = VehiclesReducer

  return {
    financeExampleLanding, vehiclesCompareMax, metasHomeTitle,
    metasHomeDescription, cookiesBannerScript, vehicleLandingBanner,
    vehicleLandingGalleryBottom, metricsGtmEnabled, metricsGtmId,
    financeSimulationAmountStep, financeAllowedMonths, financeSimulationMinAmount,
    dataComparator,
    dataVehicleDetail, isLoadingVehicle, initialEntry,
    postponement, takeOutInsurance, interestingOption,
    resultFinancing, isLoadingFinancing,
  }
};

const mapStateToPropsAction = {
  apiPostFinancingSimulator,
  addComparator,
  apiGetVehicle,
  clearDataVehicle,
  clearFinancingSimulator,
  setVehiclesState,
};

export default connect(mapStateToProps, mapStateToPropsAction)(VehiclesDetailsContainer);
