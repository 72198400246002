import React, {Component} from 'react';
import { connect } from 'react-redux';

//Actions
import {removeComparator, setComparatorState} from '../../../../common/modules/comparator/ComparatorActions';

//Components
import HeaderVehiclesComponents from './componentes/HeaderVehiclesComponents';

//Resources
import VehiclesManager from '../../../../common/modules/vehicles/VehiclesManager';
import {
  removeTooltips,
  startTooltips,
  startSelectPicker,
} from '../../resources/styles/js/custom';
import {comparatorColor, comparatorPrice, comparatorVehiclePower, comparatorVehicleSelect} from '../../../../common/modules/comparator/ComparatorManager';
import {strings} from '../../../../common/resources/locales/i18n';
import SelectInput from '../../components/SelectInput';
import ModalReservation from '../vehicles/ModalReservation';
import Routing from '../routing/Routing';
import {Link} from 'react-router-dom';
import { legalScripts_Default } from '../../../../common/utils/Utils';


class ComparatorContainer extends Component {

  state = {
    vehicle: {}
  }

  async componentDidMount() {
    const { metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId,   } = this.props;

    if (legalScripts_Default) legalScripts_Default({metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId})
    
    startTooltips();
    startSelectPicker();
  }

  render() {
    const { dataComparator, filterComparator} = this.props;

    if (dataComparator && dataComparator.length === 0){
      return (
        <>
        <main id="main" className="pb-0" role="main">
          <section id="pagina-comparativa" className="container">
            <div className='d-flex flex-column w-100 align-items-center'>
              {strings('comparator.emptyList')}
              <Link className='btn btn-sand btn-outline-primary px-lg-4 text-uppercase mt-4' replace to={Routing.home}>
                {strings('msgConfirmation.backHomepage')}
              </Link>
            </div>
          </section>
        </main>
        </>
      )
    }

    return(
      <>
      <main id="main" className="pb-0 pt-0" role="main">
        <section id="pagina-comparativa" className="container pt-5">
          <div className="table-responsive">
            <table className="table table-layout-fixed fs-sm" style={{minWidth: 45}}>
              <thead>
              <tr>
                <td className="pb-3 align-bottom border-0" style={{width: '25%'}}>
                  <SelectInput
                    id={'filterComparator'}
                    title={strings('comparator.quickFiltering')}
                    dataChildren={comparatorVehicleSelect}
                    showSelect={false}
                    onChange={(e) => this._handleValueChange(e.target.id, Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    ))}
                    value={filterComparator}
                    getItemId={true}
                    styleContainer={'mb-3'}
                    styleTitle={'mb-2 small font-weight-bold'}
                    customStyleSelect={'form-control selectpicker mb-2'}
                    multiple={true}
                  />

                </td>
                {this._renderHeaderVehicles()}
              </tr>
              </thead>
              {this._renderTable(comparatorVehiclePower)}
              {this._renderTable(comparatorPrice)}
              {this._renderTable(comparatorColor)}
              {this._renderTableButton(comparatorColor)}
            </table>
          </div>
        </section>
        <ModalReservation vehicle={this.state.vehicle} history={this.props.history}/>
      </main>
      </>
    )
  }

  /*PRIVATE METHOD */

  _handleValueChange = (prop, value) => {

    this.props.setComparatorState({ prop, value: value });
  };

  _removeComparator(vehicle){
    this.props.removeComparator(vehicle);
    removeTooltips()
  }

  _renderHeaderVehicles() {
    const {dataComparator} = this.props;

    return dataComparator.map((item, index) => {
      const productId = VehiclesManager.getProductId(item);
      const modelDescription = VehiclesManager.getModelGroupDescription(item);
      const lineDescription = VehiclesManager.getProductLineDescription(item);
      const nameVehicle = `${modelDescription} ${lineDescription}`;
      const imageMain = VehiclesManager.getImageMain(item);
      const imagesList = VehiclesManager.getImagesList(item);
      const finalPriceFormat = VehiclesManager.getFinalPriceFormat(item);
      const cuotaPriceFormat = VehiclesManager.getCuotaPriceFormat(item);
      const gearboxDescription = VehiclesManager.getGearboxDescription(item);
      const fuelDescription = VehiclesManager.getFuelDescription(item);
      const colorDescription = VehiclesManager.getColorDescription(item);
      const modelGroupDescription = VehiclesManager.getModelGroupDescription(item);

      return(
        <HeaderVehiclesComponents
          key={index}
          id={productId}
          name={nameVehicle}
          image={imageMain ? imageMain : imagesList && imagesList[0]}
          finalPriceFormat={finalPriceFormat}
          cuotaPriceFormat={cuotaPriceFormat}
          gearboxDescription={gearboxDescription}
          fuelDescription={fuelDescription}
          colorDescription={colorDescription}
          removeComparator={() => {this._removeComparator(item)}}
          modelGroupDescription={modelGroupDescription}
          showModal={() => {
            this.setState({vehicle: item})
          }}
        />
      )
    })
  }

  _renderTable({id, title, titleMethod, typeFilter, item}) {
    const { filterComparator } = this.props;

    //Render all options
    if (!filterComparator || filterComparator.length === 0){
      return(
        <tbody id={id}>
        <tr className="bg-white">
          {<th className="text-uppercase text-dark h6">{title}</th>}
          {this._renderTableTitle(titleMethod)}
        </tr>
        {
          item.map(({title, method}, index) => {
            return (
              <tr key={index}>
                <th className="text-dark">{title}</th>
                {this._renderTableDescription(method)}
              </tr>
            )
          })
        }
        </tbody>
      )
    }

    //Only render options check
    return filterComparator.map((element, key) =>{

      if (element === typeFilter){
        return(
          <tbody id={id} key={key}>
          <tr className="bg-white">
            {<th className="text-uppercase text-dark h6">{title}</th>}
            {this._renderTableTitle(titleMethod)}
          </tr>
          {
            item.map(({title, method}, index) => {
              return (
                <tr key={index}>
                  <th className="text-dark">{title}</th>
                  {this._renderTableDescription(method)}
                </tr>
              )
            })
          }
          </tbody>
        )
      }
      return (<tbody key={key}/>)

    })



  }

  _renderTableButton() {
    const { dataComparator } = this.props;

    return(
      <thead>
        <tr>
          <td/>
          {dataComparator.map((item, index) => {
              return (
                <td key={index}>
                  <Link to={Routing.nowhere} onClick={() => this.setState({vehicle: item})} className="btn btn-primary d-block w-100" role="button" data-toggle="modal"
                        data-target="#modalReservation">
                    {strings('button.imInterested')}
                  </Link>
                </td>
              )
            })
          }
        </tr>
      </thead>
      )
  }

  _renderTableTitle(method) {
    const {dataComparator} = this.props;

    return dataComparator.map((item, index) => {
      const value = method(item)
      return(
        <td key={index} className="text-dark h6">{value}</td>
      )
    })
  }

  _renderTableDescription(method) {
    const {dataComparator} = this.props;

    return dataComparator.map((item, index) => {
      const value = method ? method(item) : ''
      return(
        <td key={index}>{value}</td>
      )
    })
  }

}

const mapStateToProps = ({ AppReducer, ComparatorReducer }) => {
  const {metasHomeTitle, metasHomeDescription, cookiesBannerScript,metricsGtmEnabled, metricsGtmId} = AppReducer;
  const { dataComparator, filterComparator } = ComparatorReducer;

  return {
    metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId,
    dataComparator, filterComparator
  };

};

const mapStateToPropsActions = {
  removeComparator,
  setComparatorState
};

export default connect(mapStateToProps, mapStateToPropsActions)(ComparatorContainer);
