import Config from "react-environment";
import {
  addCustomHeadSEO,
} from '../../web/src/resources/styles/js/ScriptsAudi';

export const isApp = () => {
  return Config.REACT_APP_IS_APP === 'true'
}

export const isDev = () => {
  return Config.REACT_APP_IS_DEV === 'true'
}

export const isPro = () => {
  return Config.REACT_APP_IS_PRO === 'true'
}

export const isWeb = () => {
  return Config.REACT_APP_IS_WEB === 'true'
}
export const showEnvName = () => {
  if (isDev()) {
    console.log('*************')
    console.log(Config.REACT_APP_NAME)
    console.log('*************')
  }
}

export const formatToPrice = (data) => {
  if (data) {
    const options1 = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 };
    const numberFormat1 = new Intl.NumberFormat('es-ES', options1);
    return numberFormat1.format(data)
  }
}

export const parseStringVar = (string) => {
  let arr = string.split('_');
  let result = '';

  for (let i = 0; i < arr.length; i++) {
    if (i !== 0) {
      let str = arr[i].toLowerCase();
      arr[i] = str.charAt(0).toUpperCase() + str.slice(1);
    }
    result += arr[i];
  }
  return result;
}

export const parseSelect = (data) => {
  const result = []

  data.map((item) => {
    result.push({ id: item.value_id, value: item.value_label })
  })

  return result;
}

/* LEGALS SCRIPTS */
export const legalScripts_Default = async (props) => {
  const { metasHomeTitle, metasHomeDescription } = props;

  addCustomHeadSEO(
    'default', { metasHomeTitle, metasHomeDescription }
  );
}

export const legalScripts_VehiclesContainer = async (props) => {
  const { metasModelTitle, currentModelDescription, metasModelDescription, allVehiclesMetadesc, allVehiclesMetatitle, idParams, isPromotion, promoVehiclesMetadesc, promoVehiclesMetatitle } = props;

  addCustomHeadSEO(
    'vehicles_container', { metasModelTitle, currentModelDescription, metasModelDescription, allVehiclesMetadesc, allVehiclesMetatitle, idParams, isPromotion, promoVehiclesMetadesc, promoVehiclesMetatitle }
  );
}

export function addPixelFacebook() {
  $(document).ready(function(){
    /* Script */
    // let script = document.createElement('script');
    // script.innerText = `!function (f, b, e, v, n, t, s) {if (f.fbq) return; n = f.fbq = function () {n.callMethod ?n.callMethod.apply(n, arguments) : n.queue.push(arguments)};if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';n.queue = []; t = b.createElement(e); t.async = !0;t.src = v; s = b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t, s)}(window, document, 'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '595652818527082');fbq('track', 'PageView');`;
    /* Noscript */
    // let noscript = document.createElement('noscript');
    // let img = document.createElement('img');
    // img.width = '1';
    // img.height = '1';
    // img.style = 'display:none';
    // img.src = 'https://www.facebook.com/tr?id=595652818527082&ev=PageView&noscript=1';
    // noscript.append(img);
    /* Add script and noscript */
    // document.head.appendChild(script);
    // document.head.appendChild(noscript);
  })
}

const keyParamsAllow = [
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

export const getQueryParamsUTM = (pathname) => {
  if (pathname) {
    const queryParams = pathname.split('?');
    
    if (queryParams?.length > 1) {
      const params = queryParams[1];
      const arrParams = params.split('&');
      
      if (arrParams?.length > 0) {
        let keyParam = '';
        for (let i = 0; i < arrParams.length; i++) {
          keyParam = arrParams[i].split('=');
          if (keyParam?.length > 0) {
            if (!keyParamsAllow.includes(keyParam[0])) {
              arrParams[i] = '';
            }
          } else arrParams[i] = '';
        }
      }
      
      let queryParamsVerify = '';
      for (let i = 0; i < arrParams.length; i++) {
        if (arrParams[i] && i > 0) {
          queryParamsVerify += '&' + arrParams[i];
        } else if (arrParams[i]) {
          queryParamsVerify += '?' + arrParams[i];
        }
      }
      
      return !queryParamsVerify ? '' : queryParamsVerify;
    }
  }
  
  return '';
}

export const parseQueryParamsUTM = ({pathname, search, history}) => {
  if (pathname && search && history) {
    const queryParams = getQueryParamsUTM(search);
    
    if (queryParams) {
      history.replace(pathname + queryParams);
    }
  }
}
