import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Components
import { ImageSvg } from '../../components/ImageSvg';

//Resources
import { getAsset } from '../../../../common/resources/assets/assets';
import { strings } from '../../../../common/resources/locales/i18n';
import Routing from '../routing/Routing';
import {hiddenModal} from '../../resources/styles/js/custom';
import { connect } from 'react-redux';
import { legalScripts_Default } from '../../../../common/utils/Utils';

class MessageConfirmation extends Component {

  async componentDidMount() {
    const { metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId } = this.props;

    () => window.location.reload();

    if (legalScripts_Default) legalScripts_Default({
      metasHomeTitle, metasHomeDescription, 
      cookiesBannerScript, metricsGtmEnabled, metricsGtmId
    });
    
    hiddenModal();
  }

  render() {
    const { location} = this.props;
    let msg;
    if (location && location.state && location.state.message) {
      msg = location.state.message;
    }

    return (
      <>
      <main id="main" role="main">
        <section id="message-confirmation">
          <div className="container">
            <div className="confirmation-page text-center">
              <ImageSvg src={getAsset('icAdviser2')}/>
              <h1 className="text-dark mt-3">{strings('msgConfirmation.thank')}</h1>
              <div className="text lead mb-5">
                <span className='mb-0' dangerouslySetInnerHTML={{ __html: msg ? msg : ''}} />
              </div>
              <Link to={Routing.home} className="btn btn-lg btn-primary">
                {strings('msgConfirmation.backHomepage')}
              </Link>
            </div>
          </div>
        </section>
      </main>
      </>
    )
  }

}

const mapStateToProps = ({ AppReducer }) => {
  const { metasHomeTitle, metasHomeDescription, cookiesBannerScript,metricsGtmEnabled, metricsGtmId } = AppReducer;

  return {
    metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId 
  }
};

export default connect(mapStateToProps, null)(MessageConfirmation);
