import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Config from 'react-environment';

//Actions
import {
  apiGetVehiclesSearch,
  setVehiclesState,
} from '../../../../common/modules/vehicles/VehiclesActions';

//Components
import ModalFilter from './ModalFilter';


// Resources
import Routing from '../routing/Routing';
import { strings } from '../../../../common/resources/locales/i18n';
import { getAsset } from '../../../../common/resources/assets/assets';
import { ImageSvg } from '../../components/ImageSvg';

class NavBar extends Component {

  render() {
    const { dataComparator, history, parentSiteUrl, location} = this.props;
    const pathname = location.pathname;

    return (
      <>
        <div id="topnav" className="py-0 bg-white border-bottom">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <Link to={{ pathname: parentSiteUrl }} className="text-muted btn btn-text px-0" target="_blank">
                <ion-icon name="globe-outline" />
                <span className="ml-1">
                  {strings('navBar.backToSite')}
                </span>
              </Link>

              <ul id="social-list" className="list-inline mb-0 lead">
                {Config.REACT_APP_RS_INSTAGRAM
                  ? <li className="list-inline-item">
                    <Link to={{ pathname: Config.REACT_APP_RS_INSTAGRAM }} className="text-muted" target="_blank" title="Instagram">
                      <ion-icon name="logo-instagram" />
                    </Link>
                  </li>
                  : <></>}
                {Config.REACT_APP_RS_FACEBOOK
                  ? <li className="list-inline-item">
                    <Link to={{ pathname: Config.REACT_APP_RS_FACEBOOK }} className="text-muted" target="_blank" title="facebook">
                      <ion-icon name="logo-facebook" />
                    </Link>
                  </li>
                  : <></>}
                {Config.REACT_APP_RS_TWITTER
                  ? <li className="list-inline-item">
                    <Link to={{ pathname: Config.REACT_APP_RS_TWITTER }} className="text-muted" target="_blank" title="Twitter">
                      <ion-icon name="logo-twitter" />
                    </Link>
                  </li>
                  : <></>}
                {Config.REACT_APP_RS_YOUTUBE
                  ? <li className="list-inline-item">
                    <Link to={{ pathname: Config.REACT_APP_RS_YOUTUBE }} className="text-muted" target="_blank" title="youtube">
                      <ion-icon name="logo-youtube" />
                    </Link>
                  </li>
                  : <></>}
                {Config.REACT_APP_RS_LINKEDIN
                  ? <li className="list-inline-item">
                    <Link to={{ pathname: Config.REACT_APP_RS_LINKEDIN }} className="text-muted" target="_blank" title="youtube">
                      <ion-icon name="logo-linkedin" />
                    </Link>
                  </li>
                  : <></>}
              </ul>

            </div>
          </div>
        </div>

        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white border-bottom">
          <div className="container">

            <Link replace to={Routing.nowhere} onClick={() => history.goBack()} role="button" className="text-muted mr-2 d-lg-none" title="Ir atrás">
              <ion-icon name="arrow-back-outline" />
            </Link>

            <Link to={Routing.home} className="navbar-brand">
              <ImageSvg src={getAsset("imgLogoAudi")} />
            </Link>

            <button className="navbar-toggler ml-auto mr-2 mr-lg-3" type="button" data-toggle="collapse"
              data-target="#navbarMain" aria-controls="navbarMain" aria-expanded="false"
              aria-label="Toggle navigation">
              <ion-icon name="menu-outline" />
            </button>

            <Link to={Routing.nowhere} id="searchMobile" className="d-lg-none" role="button" data-toggle="modal" data-target="#modalFilter">
              <ion-icon name="funnel-outline"/>
            </Link>

            <div className="collapse navbar-collapse" id="navbarMain">

              <ul className="navbar-nav navbar-left">
                <li className="nav-item">
                  <Link to={Routing.vehiclesAllStock} className={`nav-link ${
                    pathname.includes(Routing.vehicle) && pathname !== Routing.vehiclePromotion ||
                    pathname.includes(Routing.vehiclesAllStock) && pathname !== Routing.vehiclePromotion ||
                    pathname === Routing.home ? 
                    'active' : ''}`}>
                    <ion-icon name="grid-outline" class="d-lg-none"/>
                    <span className="ml-2 ml-md-1">{strings('navBar.viewStock')}</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={Routing.vehiclePromotion} className={`nav-link ${
                    pathname === Routing.vehiclePromotion 
                    ? 'active' : ''}`}>
                    <ion-icon name="pricetag-outline" class="d-lg-none"/>
                    <span className="ml-2 ml-md-1">{strings('navBar.promotions')}</span>
                  </Link>
                </li>
              </ul>

              <ul className="navbar-nav navbar-right ml-auto">

                <li className="nav-item">
                  <Link to={Routing.comparator} className={`nav-link ${
                    pathname === Routing.comparator 
                    ? 'active' : ''}`} title="Comparador">
                    <ion-icon name="copy-outline" />
                    <span className="ml-2 ml-md-1">{strings('navBar.comparator', { value: dataComparator.length })}</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={Routing.contact} className={`nav-link ${
                    pathname === Routing.contact 
                    ? 'active' : ''}`} title={strings('navBar.contactAdvisor')}>
                    <ion-icon name="chatbubbles-outline" />
                    <span className="ml-2 ml-md-1">{strings('navBar.contactAdvisor')}</span>
                  </Link>
                </li>
                <li className="nav-item d-none d-lg-flex">
                  <Link replace to={Routing.nowhere} className="nav-link" role="button" data-toggle="modal" data-target="#modalFilter">
                    <ion-icon name="search-outline" />
                    <span className="ml-2 ml-md-1">{strings('navBar.searchFilter')}</span>
                  </Link>
                </li>

                <li className="nav-item d-none" id="nav-search">
                  <Link replace to={Routing.nowhere} className="nav-link" role="button" id="icon-search" title="Buscar/Filtrar">
                    <ion-icon name="search-outline" />
                    <span className="ml-2 ml-md-1">{strings('navBar.searchFilter')}</span>
                  </Link>
                  <div id="search-box" className="shadow-sm border-top">
                    <div className="container">
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </nav>
        <ModalFilter history={history} />
      </>
    );
  }
}

const mapStateToProps = ({ AppReducer, ComparatorReducer, VehiclesReducer }) => {
  const { parentSiteUrl } = AppReducer;
  const { dataComparator } = ComparatorReducer;
  const { searchBox } = VehiclesReducer;

  return {
    parentSiteUrl,
    dataComparator,
    searchBox,
  }
};

const mapStateToPropsAction = {
  apiGetVehiclesSearch,
  setVehiclesState,
};

export default connect(mapStateToProps, mapStateToPropsAction)(NavBar);
