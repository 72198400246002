//Actions
import {
  getVehicle, getVehiclePromotion, getVehicles, getVehiclesSearch, postReservation,
  postFinancingSimulator,
} from '../../services/api/API';

//Resources
import Types from './Types';
import {isDev} from '../../utils/Utils';
import VehiclesManager from './VehiclesManager';
import FormValidationManager from '../../utils/managers/FormValidationManager';
import {strings} from '../../resources/locales/i18n';
import {addToast} from '../toasts/ToastsActions';
import {ToastConstructor} from '../toasts/ToastsManager';
import {getAsset} from '../../resources/assets/assets';

export const apiGetVehicle = (id) => async(dispatch) => {
  dispatch(setVehiclesState({ prop: 'isLoadingVehicle', value: true }));
  await dispatch(getVehicle(
    id,
    (tag, response) => {
      if (isDev()) console.log('apiGetVehicle - Error: ', response);
      dispatch({ type: Types.GET_VEHICLE_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetVehicle - Success: ', response);
      dispatch({
        type: Types.GET_VEHICLE_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setVehiclesState({ prop: 'isLoadingVehicle', value: false }));
};


export const apiGetVehiclePromotion = (id) => async(dispatch) => {
  dispatch(setVehiclesState({ prop: 'isLoadingVehicles', value: true }));
  await dispatch(getVehiclePromotion(
    id,
    (tag, response) => {
      if (isDev()) console.log('apiGetVehiclePromotion - Error: ', response);
      dispatch({ type: Types.GET_VEHICLES_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetVehiclePromotion - Success: ', response);
      dispatch({
        type: Types.GET_VEHICLES_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setVehiclesState({ prop: 'isLoadingVehicles', value: false }));
};


export const apiGetVehicles = (id) => async(dispatch) => {
  dispatch(setVehiclesState({ prop: 'isLoadingVehicles', value: true }));
  await dispatch(getVehicles(
    id,
    (tag, response) => {
      if (isDev()) console.log('apiGetVehicles - Error: ', response);
      dispatch({ type: Types.GET_VEHICLES_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetVehicles - Success: ', response);
      dispatch({
        type: Types.GET_VEHICLES_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setVehiclesState({ prop: 'isLoadingVehicles', value: false }));
};

export const apiGetVehiclesSearch = (searchBox) => async(dispatch) => {
  dispatch(setVehiclesState({ prop: 'isLoadingVehicles', value: true }));
  await dispatch(getVehiclesSearch(
    searchBox,
    (tag, response) => {
      if (isDev()) console.log('apiGetVehiclesSearch - Error: ', response);
      dispatch({ type: Types.GET_VEHICLES_FAILED });
    },
    (tag, response) => {
      if (isDev()) console.log('apiGetVehiclesSearch - Success: ', response);
      dispatch({
        type: Types.GET_VEHICLES_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setVehiclesState({ prop: 'isLoadingVehicles', value: false }));
};

export const apiPostFinancingSimulator = (amount) => async(dispatch, getState) => {
  const {initialEntry, postponement} = getState().VehiclesReducer;
  dispatch(setVehiclesState({ prop: 'isLoadingFinancing', value: true }));
  await dispatch(postFinancingSimulator(
    {amount, initialEntry, postponement},
    (tag, response) => {
      if (isDev()) console.log('apiPostFinancingSimulator - Error: ', response);
      dispatch(setVehiclesState({prop: 'resultFinancing', value: []}));
    },
    (tag, response) => {
      if (isDev()) console.log('apiPostFinancingSimulator - Success: ', response);
      dispatch(setVehiclesState({prop: 'resultFinancing', value: response.items}));
    }
  ));
  dispatch(setVehiclesState({ prop: 'isLoadingFinancing', value: false }));
};

export const apiPostReservation = (vehicleId, toggleModal) => async(dispatch, getState) => {
  const {concessionaire, email, name, island, surnames, phone} = getState().VehiclesReducer;

  await dispatch(setVehiclesState({prop: 'submit', value: true}));

  // --- 1. Validate form
  const error = await FormValidationManager.formReservation({concessionaire, email, name, island, surnames, phone});
  await dispatch(setVehiclesState({prop: 'error', value: error}));
  if (error.length !== 0) {
    return
  }

  // --- 2. Post reservation and close modal
  await dispatch(setVehiclesState({prop: 'isLoadingReservation', value: true}));

  await dispatch(postReservation(
    vehicleId,
    async (tag, response) => {
      if (isDev()) console.log('apiPostReservation - Error: ', response);

      let message = strings('error.server400');
      if (response.code === '-1' && response.message && response.message.length !== 0) {
        message = response.message;
      }
      await dispatch(addToast(
        ToastConstructor({
          icon: getAsset('icError'),
          title: strings('toasts.alert.weSorry'),
          delay: 5000,
          body: message,
        })
      ))
    },
    async (tag, response) => {
      if (isDev()) console.log('apiPostReservation - Success: ', response);
      await dispatch(clearDataReservation());

      let message = strings('msgConfirmation.backHomepage')
      if (response.code === '0' && response.message && response.message.length !== 0){
        message = response.message;
      }

      if(toggleModal){
        toggleModal(message);
      }
    }
  ));

  await dispatch(setVehiclesState({prop: 'isLoadingReservation', value: false}));
};

export const clearDataReservation = () => ({
  type: Types.CLEAR_RESERVATION,
});

export const clearDataVehicle = () => ({
  type: Types.CLEAR_VEHICLE,
});

export const clearDataVehicles = () => ({
  type: Types.CLEAR_VEHICLES,
});

export const clearFinancingSimulator = () => ({
  type: Types.CLEAR_FINANCING_SIMULATOR,
});

export const setVehiclesState = ({ prop, value }) => ({
  type: Types.SET_VEHICLES_STATE,
  payload: { prop, value }
});

export const orderByLowerPrice = () => async(dispatch, getState) => {
  const { dataVehiclesOriginal } = getState().VehiclesReducer
  dataVehiclesOriginal.sort((a, b) => {
    if(VehiclesManager.getFinalPrice(a) < VehiclesManager.getFinalPrice(b)) return -1;
    if(VehiclesManager.getFinalPrice(a) > VehiclesManager.getFinalPrice(b)) return 1;
    return 0;
  })

  dispatch(filterVehicles())
};

export const orderByMorePrice = () => async(dispatch, getState) => {
  const { dataVehiclesOriginal } = getState().VehiclesReducer
  dataVehiclesOriginal.sort((a, b) => {
    if(VehiclesManager.getFinalPrice(a) > VehiclesManager.getFinalPrice(b)) return -1;
    if(VehiclesManager.getFinalPrice(a) < VehiclesManager.getFinalPrice(b)) return 1;
    return 0;
  })

  dispatch(filterVehicles())
};

export const orderByOffersAndPromotions = () => async(dispatch, getState) => {
  const { dataVehiclesOriginal } = getState().VehiclesReducer
  dataVehiclesOriginal.sort((a, b) => {
    if(VehiclesManager.getFinalPrice(a) > VehiclesManager.getFinalPrice(b)) return 1;
    if(VehiclesManager.getFinalPrice(a) < VehiclesManager.getFinalPrice(b)) return -1;
    return 0;
  })

  dataVehiclesOriginal.sort((a, b) => {
    if(VehiclesManager.getHasSpecialPrice(a) === true && VehiclesManager.getHasSpecialPrice(b) === false ) return -1;
    if(VehiclesManager.getHasSpecialPrice(a) === false && VehiclesManager.getHasSpecialPrice(b) === true ) return 1;
    return 0;
  })

  dispatch(filterVehicles())
};

export const filterVehicles = () => async(dispatch, getState) => {
  const { dataVehiclesOriginal, colors, fuels, transmissions, price, specialPrice, cuota, equipment, models} = getState().VehiclesReducer;

  if (!dataVehiclesOriginal || dataVehiclesOriginal.length === 0){
    return
  }

  let dataVehicles = dataVehiclesOriginal.slice();

  if (cuota){
    dataVehicles = dataVehicles.filter((item) => VehiclesManager.getCuotaPrice(item) <= cuota)
  }

  if (price){
    dataVehicles = dataVehicles.filter((item) => VehiclesManager.getFinalPrice(item) <= price)
  }

  if (colors && colors.length !== 0){
    dataVehicles = dataVehicles.filter((item) => colors.includes(VehiclesManager.getColorGroupId(item)));
  }

  if (transmissions && transmissions.length !== 0){
    dataVehicles = dataVehicles.filter((item) => transmissions.includes(VehiclesManager.getGearboxDescription(item)));
  }

  if (fuels && fuels.length !== 0){
    dataVehicles = dataVehicles.filter((item) => fuels.includes(VehiclesManager.getFuelId(item)));
  }

  if (specialPrice){
    dataVehicles = dataVehicles.filter((item) => VehiclesManager.getHasSpecialPrice(item));
  }

  if (equipment && equipment.length !== 0) {
    dataVehicles = dataVehicles.filter((item) => {
      let optionalEquipmentFilter = VehiclesManager.getOptionalEquipmentFilter(item);
      for (let i = 0; i < optionalEquipmentFilter.length; i++) {
        if (equipment.includes(optionalEquipmentFilter[i])) return item;
      }
    });
  }

  if (models && models?.length > 0) {
    //models?.includes(''), es que ha escogido "Todos los modelos"
    dataVehicles = dataVehicles?.filter(item => models?.includes(item?.model_group_id) || models?.includes(''))
  }

  dispatch(setVehiclesState({ prop: 'dataVehicles', value: dataVehicles }));

};

export const validateFormReservation = () => async(dispatch, getState) => {
  const {concessionaire, email, island, name, rgpd, surnames, phone} = getState().VehiclesReducer;
  const error = await FormValidationManager.formReservation({concessionaire, email, island, name, rgpd, surnames, phone});
  dispatch(setVehiclesState({prop: 'error', value: error}));
};
